import React from "react";

import { Link } from "gatsby";

import { ListItem, ListItemLabel } from "baseui/list";
import { Check } from "baseui/icon";

import { Paragraph1, Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";

const Services = () => {
  return (
    <Page title="Услуги Фонда" path="/fund/services" parent="fund">
      <Paragraph1>
        Микрокредитная компания Архангельский региональный фонд "Развитие"
        предоставляет микрозаймы субъектам малого и среднего предпринимательства
        Архангельской области.
      </Paragraph1>
      <Paragraph1>Основные цели и задачи Фонда:</Paragraph1>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel>
          Повышение доступности заемных средств для малого и среднего бизнеса
        </ListItemLabel>
      </ListItem>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel>
          Создание, развитие и укрепление системы микрофинансирования
        </ListItemLabel>
      </ListItem>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel>
          Обеспечение условий для самозанятости населения и реализации им
          предпринимательских инициатив
        </ListItemLabel>
      </ListItem>
      <Paragraph1>Микрозаём - это:</Paragraph1>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel>сумма от 30 тыс. руб. до 5 млн руб.</ListItemLabel>
      </ListItem>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel>
          срок от 3 мес. до 3 лет (с 18.07.2020 и до окончания действия{" "}
          <StyledLink
            href="https://dvinaland.ru/covid19/"
            target="_blank"
            rel="noopener noreferrer"
          >
            режима повышенной готовности
          </StyledLink>{" "}
          - до 2 лет)
        </ListItemLabel>
      </ListItem>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel
          description="действующая
          на дату заключения договора займа"
        >
          ставка, не превышающая размер ключевой ставки Банка России
        </ListItemLabel>
      </ListItem>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel>
          погашение ежемесячными равными срочными уплатами (аннуитетными
          платежами)
        </ListItemLabel>
      </ListItem>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel>
          отсутствие "комиссий" и "скрытых платежей"
        </ListItemLabel>
      </ListItem>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel
          description="в
          зависимости от сезонности и условий ведения бизнеса"
        >
          предоставление льготного периода (только уплата процентов)
        </ListItemLabel>
      </ListItem>
      <ListItem artwork={(props) => <Check {...props} />}>
        <ListItemLabel
          description="после 3-х
          месяцев со дня предоставления займа без дополнительных расходов
          заемщика"
        >
          возможность полного досрочного погашения задолженности
        </ListItemLabel>
      </ListItem>
      <Paragraph1>
        Микрозаймы в размере до 500 тыс. руб. включительно могут предоставляться
        без залога, под поручительства юридических или физических лиц.
      </Paragraph1>
      <Paragraph1>
        Для микрозаймов в размере от 1 млн руб., а также для займов по линии
        фонда развития промышленности* возможно предоставление поручительства
        региональной гарантийной организации (РГО){" "}
        <StyledLink
          href="http://icarh.ru/"
          target="_blank"
          rel="noopener noreferrer"
        >
          АО "РГО Архангельской области"
        </StyledLink>
        . Возможно предоставление гарантии АО "Федеральная корпорация по
        развитию малого и среднего предпринимательства" (
        <StyledLink
          href="https://corpmsp.ru/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Корпорация МСП
        </StyledLink>
        ), а также совместной гарантии Корпорации МСП и РГО.
      </Paragraph1>
      <Paragraph1>
        С Правилами и программами микрокредитования, а также действующими
        ставкам по микрозаймам можно ознакомиться в разделе{" "}
        <StyledLink to="/programs" $as={Link}>
          программы поддержки
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        Рассчитать будущие платежи по микрозайму можно при помощи{" "}
        <StyledLink to="/calculator" $as={Link}>
          онлайн-калькулятора
        </StyledLink>
        .
      </Paragraph1>
      <Paragraph1>
        <Label1>
          * Фонд "МКК Развитие" осуществляет функции и полномочия{" "}
          <StyledLink to="/frp" $as={Link}>
            регионального фонда развития промышленности
          </StyledLink>
          .
        </Label1>
      </Paragraph1>
    </Page>
  );
};

export default Services;
